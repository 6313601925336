<template>
  <div
    v-if="(!type || type === 'property') && property"
    :style="{ visibility: shown ? 'visible' : 'hidden' }"
    class="modal-overlay relative"
    @click="$emit('close-modal')"
  >
    <div class="modal slim-scrollbar overflow-auto" @click.stop>
      <div
        :style="{
          backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${$api}/uploads/${property.featuredImage}) `,
        }"
        style="background-size: cover; background-position: center"
        class="w-full mx-auto relative px-4 p-20 h-60 bg-green-800"
      >
        <div class="close absolute" @click="$emit('close-modal')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M3 3h18v18H3zM15 9l-6 6m0-6l6 6" />
          </svg>
        </div>
        <div
          class="
            max-w-6xl
            mx-auto
            container
            text-white
            mt-6
            xl:px-0
            lg:px-16
            md:px-16
            sm:px-20
            px-2
          "
        >
          <span class="text-xs uppercase"> {{ property.location }} </span>
          <h1 class="text-3xl uppercase">{{ property.name }}</h1>
        </div>
      </div>
      <div class="sm:flex text-xs text-white">
        <div class="bg-primary p-2 w-full">
          {{ property.starRating ? property.starRating.name : "-" }}
        </div>
        <div class="bg-primarydark p-2 w-full">
          {{ property.transferType ? property.transferType.name : "-" }}
        </div>
      </div>
      <div class="text-xs qview-description p-3 text-gray-400 mt-7 leading-6">
        <div class="" v-html="property.description"></div>
      </div>

      <!-- <div class="flex justify-center">
        <div class="w-1/5 text-left">
          <p class="text-xs text-gray-500 mt-2">STAR RATING</p>
        </div>
        <div class="w-1/2 text-left">
          <p class="text-xs text-gray-500 mt-2">
            <span class="text-black">{{
              property.starRating ? property.starRating.name : "-"
            }}</span>
          </p>
        </div>
      </div>
      <div class="flex justify-center">
        <div class="w-1/5 text-left">
          <p class="text-xs text-gray-500 mt-3">TRANSFER</p>
        </div>
        <div class="w-1/2 text-left">
          <p class="text-xs text-gray-500 mt-3">
            <span class="text-black">
              {{ property.starRating ? property.starRating.name : "-" }}
            </span>
          </p>
        </div>
      </div> -->

      <div class="w-full mx-auto">
        <button
          @click="$router.push(`/properties/${property._id}`)"
          class="
            uppercase
            text-white text-sm
            bg-enquirybutton
            hover:bg-enquiryborder
            px-6
            py-3
            my-10
          "
        >
          VIEW ROOMS
        </button>
      </div>
    </div>
  </div>
  <div
    v-if="type === 'specialOffer' && specialOffer"
    :style="{ visibility: shown ? 'visible' : 'hidden' }"
    class="modal-overlay relative"
    @click="$emit('close-modal')"
  >
    <div class="modal slim-scrollbar overflow-auto" @click.stop>
      <div
        :style="{
          backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${$api}/uploads/${specialOffer.featuredImage}) `,
        }"
        style="background-size: cover; background-position: center"
        class="w-full mx-auto relative px-4 p-20 h-60 bg-green-800"
      >
        <div class="close absolute" @click="$emit('close-modal')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M3 3h18v18H3zM15 9l-6 6m0-6l6 6" />
          </svg>
        </div>
        <div
          class="
            max-w-6xl
            mx-auto
            container
            text-white
            mt-6
            xl:px-0
            lg:px-16
            md:px-16
            sm:px-20
            px-2
          "
        >
          <span class="text-xs uppercase">
            {{ specialOffer.property ? specialOffer.property.name : "" }}
          </span>
          <h1 class="text-3xl uppercase">{{ specialOffer.name }}</h1>
        </div>
      </div>
      <div class="sm:flex text-xs text-white">
        <div class="bg-primary p-2 w-full">
          BOOK BEFORE: <br />
          {{ $formatDate(specialOffer.bookBefore) }}
        </div>
        <div class="bg-primarydark p-2 w-full">
          TRAVEL BEFORE: <br />
          {{ $formatDate(specialOffer.stayBetweenEnd) }}
        </div>
      </div>
      <div class="text-xs qview-description p-3 text-gray-400 mt-7 leading-6">
        <div class="" v-html="specialOffer.description"></div>
      </div>

      <!-- <div class="flex justify-center">
        <div class="w-1/5 text-left">
          <p class="text-xs text-gray-500 mt-2">STAR RATING</p>
        </div>
        <div class="w-1/2 text-left">
          <p class="text-xs text-gray-500 mt-2">
            <span class="text-black">{{
              property.starRating ? property.starRating.name : "-"
            }}</span>
          </p>
        </div>
      </div>
      <div class="flex justify-center">
        <div class="w-1/5 text-left">
          <p class="text-xs text-gray-500 mt-3">TRANSFER</p>
        </div>
        <div class="w-1/2 text-left">
          <p class="text-xs text-gray-500 mt-3">
            <span class="text-black">
              {{ property.starRating ? property.starRating.name : "-" }}
            </span>
          </p>
        </div>
      </div> -->

      <div class="w-full mx-auto">
        <button
          @click="
            $router.push(`/enquiry?type=specialOffer&offer=${specialOffer._id}`)
          "
          class="
            uppercase
            text-white text-sm
            bg-enquirybutton
            hover:bg-enquiryborder
            px-6
            py-3
            my-10
          "
        >
          BOOK NOW
        </button>
      </div>
    </div>
  </div>
</template>
 



<script>
export default {
  name: "QuickView",
  props: ["type", "shown", "property", "specialOffer"],
  emits: ["close-modal"],
  data() {
    return {};
  },
  methods: {},

  components: {},
};
</script>


<style scoped>
.modal-overlay {
  position: fixed;
  z-index: 10001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000da;
}

.modal {
  z-index: 10002;
  text-align: center;
  background-color: white;
  height: 500px;
  width: 820px;
}
.close {
  top: 0;
  padding: 12px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}
</style>

 