<template>
  <div>
    <!-- FAVORITE PROPERTIES -->
    <div
      class="max-w-6xl mx-auto container mx-auto bg-white mt-8 md:mt-18 mb-20"
    >
      <div class="container mx-auto">
        <section class="flex md:flex-row flex-wrap py-12">
          <div class="w-full md:w-1/2 flex items-center lg:pr-20 sm:pr-4">
            <input
              v-model="searchString"
              class="w-full text-gray-900 border-gray-400 mr-3 border-b border-t-0 border-l-0 border-r-0 px-5 py-2 text-xs focus:outline-none"
              @keydown="handleSearchKeyPress"
              @keyup="handleSearchKeyUp"
              placeholder="SEARCH OVER 100 PROPERTIES"
            />
            <Spinner :loading="loading" />
          </div>

          <div
            class="w-full md:w-1/2 sm:mt-10 md:mt-0 lg:mt-0 mt-10 text-right px-2"
          >
            <span
              to=""
              class="inline-block text-xs border border-gray-400 mr-2 hover:bg-gray-100 cursor-pointer relative"
              @click="limitDropdownShown = !limitDropdownShown"
            >
              <div
                v-show="limitDropdownShown"
                class="fixed top-0 right-0 bottom-0 left-0 z-40"
              ></div>
              <span class="inline-block px-4 md:px-8 py-3 p-2"
                >SHOW: {{ perPage }}</span
              >
              <ul
                v-show="limitDropdownShown"
                class="w-full bg-white z-50 rounded absolute border"
              >
                <li
                  @click="setLimit(5)"
                  class="text-left px-3 py-2 hover:bg-gray-100"
                >
                  5
                </li>
                <li
                  @click="setLimit(10)"
                  class="text-left px-3 py-2 hover:bg-gray-100"
                >
                  10
                </li>
                <li
                  @click="setLimit(15)"
                  class="text-left px-3 py-2 hover:bg-gray-100"
                >
                  15
                </li>
                <li
                  @click="setLimit(20)"
                  class="text-left px-3 py-2 hover:bg-gray-100"
                >
                  20
                </li>
              </ul>
            </span>
            <span
              to=""
              class="inline-block text-xs border border-gray-400 mr-2 hover:bg-gray-100 cursor-pointer relative"
              @click="starRatingDropdownShown = !starRatingDropdownShown"
            >
              <div
                v-show="starRatingDropdownShown"
                class="fixed top-0 right-0 bottom-0 left-0 z-40"
              ></div>
              <span class="inline-block px-4 md:px-8 py-3 p-2">{{
                starRating ? starRating.name : "STAR RATING"
              }}</span>
              <ul
                v-show="starRatingDropdownShown"
                class="w-full bg-white z-50 rounded absolute border"
              >
                <li
                  v-if="starRating"
                  @click="setStarRating(null)"
                  style="background: #a29c88"
                  class="text-left text-white px-3 py-2 hover:bg-gray-100"
                >
                  Clear Filter
                </li>
                <li
                  v-for="rating in starRatings"
                  :key="rating._id"
                  @click="setStarRating(rating)"
                  class="text-left px-3 py-2 hover:bg-gray-100"
                >
                  {{ rating.name }}
                </li>
              </ul>
            </span>

            <span
              to=""
              class="inline-block text-xs border border-gray-400 mr-2 hover:bg-gray-100 cursor-pointer relative"
              @click="transferTypesDropdownShown = !transferTypesDropdownShown"
            >
              <div
                v-show="transferTypesDropdownShown"
                class="fixed top-0 right-0 bottom-0 left-0 z-40"
              ></div>
              <span class="inline-block px-4 md:px-8 py-3 p-2">{{
                transferType ? transferType.name : "TRANSFER TYPE"
              }}</span>
              <ul
                v-show="transferTypesDropdownShown"
                class="w-full bg-white z-50 rounded absolute border"
              >
                <li
                  v-if="transferType"
                  @click="setTransferType(null)"
                  style="background: #a29c88"
                  class="text-left text-white px-3 py-2 hover:bg-gray-100"
                >
                  Clear Filter
                </li>
                <li
                  v-for="transfer in transferTypes"
                  :key="transfer._id"
                  @click="setTransferType(transfer)"
                  class="text-left px-3 py-2 hover:bg-gray-100"
                >
                  {{ transfer.name }}
                </li>
              </ul>
            </span>
          </div>
        </section>
      </div>

      <PropertyCard
        v-for="(property, i) in properties"
        :key="i"
        :property="property"
      ></PropertyCard>

      <!-- <div class="container mx-auto px-4">
        <section class=" border">
          <div class="flex">
            <div class="w-1/2">
              <img class="" src="https://source.unsplash.com/xPsFXsbXJRg" />
            </div>

            <div class="w-1/2 text-left p-10">
              <h1 class="text-2xl">ADAARAN CLUB RANNALHI</h1>

              <div class="flex">
                <div class="w-1/6 text-left">
                  <p class="text-xs text-gray-500 mt-6 ">
                    STAR RATING
                  </p>
                </div>
                <div class="w-1/2 text-left">
                  <p class="text-xs text-gray-500 mt-6 ">
                    <span class="text-black pl-3"> 3 STAR BUDGET</span>
                  </p>
                </div>
              </div>

              <div class="flex">
                <div class="w-1/6 text-left">
                  <p class="text-xs text-gray-500 mt-3 ">
                    TRANSFER
                  </p>
                </div>
                <div class="w-1/2 text-left">
                  <p class="text-xs text-gray-500 mt-3 ">
                    <span class="text-black pl-3"> 25 MINUTE SPEEDBOAT</span>
                  </p>
                </div>
              </div>

              <p class="mt-8 text-xs">
                Escape to beachside hideaway surrounded by an abundance of
                indigenous, tropical flora. A typical morning greets you with
                views of waves. Enchanting sun and winds.
              </p>
              <p class="mt-6 ml-2 text-xs">
                Light flooded open-plan living area with retro fitting,
                fireplace, tv and seaplace lions.
              </p>
            </div>
          </div>

          <div class="w-full  text-right text-xs">
            <router-link to="" class="bg-gray-800 p-2 px-8 py-4 text-white"
              >VIEW RESORT</router-link
            >
          </div>
        </section>
      </div> -->

      <div class="flex justify-center mx-auto px-4 m-10">
        <!-- PAGINATION-->
        <Pagination
          v-if="paginationData"
          :paginationData="paginationData"
          url="/properties/page/"
        ></Pagination>
      </div>
    </div>
  </div>
  <a
    href="https://api.whatsapp.com/send?phone=+9607944493&text=Hi"
    class="float"
    target="_blank"
  >
    <!-- <svg
        class="my-float"
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
        viewBox="0 0 24 24"
        fill="#25D366"
      >
        <path
          d="M12 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24zm.14 4.5a7.34 7.34 0 0 0-6.46 10.82l.15.26L4.5 19.5l4.08-1.3a7.38 7.38 0 0 0 10.92-6.4c0-4.03-3.3-7.3-7.36-7.3zm0 1.16c3.41 0 6.19 2.76 6.19 6.15a6.17 6.17 0 0 1-9.37 5.27l-.23-.15-2.38.76.77-2.28a6.08 6.08 0 0 1-1.17-3.6 6.17 6.17 0 0 1 6.19-6.15zM9.66 8.47a.67.67 0 0 0-.48.23l-.14.15c-.2.23-.5.65-.5 1.34 0 .72.43 1.41.64 1.71l.14.2a7.26 7.26 0 0 0 3.04 2.65l.4.14c1.44.54 1.47.33 1.77.3.33-.03 1.07-.43 1.22-.85.15-.42.15-.78.1-.85-.02-.05-.08-.08-.15-.12l-1.12-.54a5.15 5.15 0 0 0-.3-.13c-.17-.06-.3-.1-.41.09-.12.18-.47.58-.57.7-.1.1-.18.13-.32.08l-.4-.18a4.64 4.64 0 0 1-2.13-1.98c-.1-.18-.01-.28.08-.37l.27-.31c.1-.1.12-.18.18-.3a.3.3 0 0 0 .01-.26l-.1-.23-.48-1.15c-.15-.36-.3-.3-.4-.3l-.35-.02z"
        />
      </svg> -->

    <svg
      width="52"
      height="52"
      class="my-float"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 31C23.732 31 30 24.732 30 17C30 9.26801 23.732 3 16 3C8.26801 3 2 9.26801 2 17C2 19.5109 2.661 21.8674 3.81847 23.905L2 31L9.31486 29.3038C11.3014 30.3854 13.5789 31 16 31ZM16 28.8462C22.5425 28.8462 27.8462 23.5425 27.8462 17C27.8462 10.4576 22.5425 5.15385 16 5.15385C9.45755 5.15385 4.15385 10.4576 4.15385 17C4.15385 19.5261 4.9445 21.8675 6.29184 23.7902L5.23077 27.7692L9.27993 26.7569C11.1894 28.0746 13.5046 28.8462 16 28.8462Z"
          fill="#BFC8D0"
        ></path>
        <path
          d="M28 16C28 22.6274 22.6274 28 16 28C13.4722 28 11.1269 27.2184 9.19266 25.8837L5.09091 26.9091L6.16576 22.8784C4.80092 20.9307 4 18.5589 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
          fill="url(#paint0_linear_87_7264)"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 18.5109 2.661 20.8674 3.81847 22.905L2 30L9.31486 28.3038C11.3014 29.3854 13.5789 30 16 30ZM16 27.8462C22.5425 27.8462 27.8462 22.5425 27.8462 16C27.8462 9.45755 22.5425 4.15385 16 4.15385C9.45755 4.15385 4.15385 9.45755 4.15385 16C4.15385 18.5261 4.9445 20.8675 6.29184 22.7902L5.23077 26.7692L9.27993 25.7569C11.1894 27.0746 13.5046 27.8462 16 27.8462Z"
          fill="white"
        ></path>
        <path
          d="M12.5 9.49989C12.1672 8.83131 11.6565 8.8905 11.1407 8.8905C10.2188 8.8905 8.78125 9.99478 8.78125 12.05C8.78125 13.7343 9.52345 15.578 12.0244 18.3361C14.438 20.9979 17.6094 22.3748 20.2422 22.3279C22.875 22.2811 23.4167 20.0154 23.4167 19.2503C23.4167 18.9112 23.2062 18.742 23.0613 18.696C22.1641 18.2654 20.5093 17.4631 20.1328 17.3124C19.7563 17.1617 19.5597 17.3656 19.4375 17.4765C19.0961 17.8018 18.4193 18.7608 18.1875 18.9765C17.9558 19.1922 17.6103 19.083 17.4665 19.0015C16.9374 18.7892 15.5029 18.1511 14.3595 17.0426C12.9453 15.6718 12.8623 15.2001 12.5959 14.7803C12.3828 14.4444 12.5392 14.2384 12.6172 14.1483C12.9219 13.7968 13.3426 13.254 13.5313 12.9843C13.7199 12.7145 13.5702 12.305 13.4803 12.05C13.0938 10.953 12.7663 10.0347 12.5 9.49989Z"
          fill="white"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_87_7264"
            x1="26.5"
            y1="7"
            x2="4"
            y2="28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5BD066"></stop>
            <stop offset="1" stop-color="#27B43E"></stop>
          </linearGradient>
        </defs>
      </g>
    </svg>
  </a>
</template>

<script>
import PropertyCard from "@/components/layout/PropertyCard";
import Pagination from "@/components/base/Pagination";

export default {
  name: "Properties",
  data() {
    return {
      currentPage: this.$route.params.pageid || 1,
      perPage: this.$route.query.limit || 15,
      propertyType: this.$route.query.type || this.$resort_type_id,
      starRating: null,
      transferType: null,
      searchString: "",
      searchTypingTimeout: 500,
      loading: false,
      paginationData: null,
      // dropdowns
      limitDropdownShown: false,
      starRatingDropdownShown: false,
      transferTypesDropdownShown: false,
      starRatings: [],
      transferTypes: [],
      properties: [
        // {
        //   name: "Test Property",
        //   starRating: "5 Star Luxury",
        //   transfer: "20 Minute Speedboat",
        // },
        // {
        //   name: "Test Property Two",
        //   starRating: "4 Star Something",
        //   transfer: "20 Minute Seaplane",
        // },
        // {
        //   name: "Test Property",
        //   starRating: "5 Star Luxury",
        //   transfer: "20 Minute Speedboat",
        // },
        // {
        //   name: "Test Property Two",
        //   starRating: "4 Star Something",
        //   transfer: "20 Minute Seaplane",
        // },
      ],
    };
  },
  methods: {
    setLimit(limit) {
      this.perPage = limit;
      if (this.$route.params.pageid == 1) {
        this.getProperties();
      } else {
        this.$router.push(`/properties/page/1?type=${this.propertyType}`);
      }
    },
    setStarRating(rating) {
      this.starRating = rating;
      if (this.$route.params.pageid == 1) {
        this.getProperties();
      } else {
        this.$router.push(`/properties/page/1?type=${this.propertyType}`);
      }
    },
    setTransferType(type) {
      this.transferType = type;
      if (this.$route.params.pageid == 1) {
        this.getProperties();
      } else {
        this.$router.push(`/properties/page/1?type=${this.propertyType}`);
      }
    },
    async getProperties() {
      try {
        let uri = `${this.$api_v1}/properties?page=${this.currentPage}&limit=${this.perPage}&type=${this.propertyType}`;

        if (this.searchString) {
          uri = `${uri}&search=${this.searchString}`;
        }

        if (this.starRating) {
          uri = `${uri}&starRating=${this.starRating._id}`;
        }

        if (this.transferType) {
          uri = `${uri}&transferType=${this.transferType._id}`;
        }

        const res = await this.$axios.get(uri);

        if (res.status === 200) {
          this.properties = res.data.properties;
          // console.log(res.data);
          this.paginationData = res.data.paginationData;
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$errorHandler(e);
      }
    },
    async getStarRatings() {
      try {
        let uri = `${this.$api_v1}/starratings`;

        const res = await this.$axios.get(uri);

        if (res.status === 200) {
          this.starRatings = res.data.starRatings;
          // console.log(res.data);=
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    async getTransferTypes() {
      try {
        let uri = `${this.$api_v1}/transfertypes`;

        const res = await this.$axios.get(uri);

        if (res.status === 200) {
          this.transferTypes = res.data.transferTypes;
          // console.log(res.data);=
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    handleSearchKeyPress() {
      window.clearTimeout(window.searchTimer);
      this.loading = true;
    },
    handleSearchKeyUp() {
      window.clearTimeout(window.searchTimer);
      window.searchTimer = window.setTimeout(() => {
        if (this.$route.params.pageid == 1) {
          this.getProperties();
        } else {
          this.$router.push(`/properties/page/1?type=${this.propertyType}`);
        }

        // console.log("test");
      }, this.searchTypingTimeout);
      // console.log(this.timer);
    },
  },
  watch: {
    async $route(to, from) {
      from;
      if (to.name == "Resorts") {
        await this.getProperties();
      }
      if (to.name == "ResortsP") {
        this.currentPage = this.$route.params.pageid;

        await this.getProperties();
      }
    },
  },
  mounted() {
    this.getProperties();
    this.getStarRatings();
    this.getTransferTypes();
  },
  components: {
    PropertyCard,
    Pagination,
  },
};
</script>

<style scoped>
.float {
  @apply md:bottom-20 bottom-20;
  position: fixed;
  width: 40px;
  height: 40px;
  right: 40px;
  color: white;
  text-align: center;

  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
</style>
